<template>
  <CFooter :fixed="false">
    <div>
      <span class="ml-1">&copy; {{new Date().getFullYear()}}.</span>
    </div>
    <div class="ml-2">
      <span class="mr-1">&nbsp;</span>
      <lang-dropdown />
    </div>
  </CFooter>
</template>

<script>
import LangDropdown from '@/components/LangDropdown.vue'

export default {
  name: "TheFooter",
  components: {
    LangDropdown,
  }
};
</script>
