<template>

    <select v-model="lang" class="lang-dropdown">
      <option
        v-for="(lang, i) in languageArray"
        :key="`lang${i}`"
        :value="lang"
      >
        {{ lang }}
      </option>
    </select>

</template>
<script>
import { languages } from '@/plugins/i18n'
export default {
  name: 'langDropdown',
  data() {
    return {
      languageArray: languages
    }
  },
  computed: {
    lang: {
      get: function() {
        return this.$store.state.locale
      },
      set: function(newVal) {
        this.$store.dispatch('changeLocale', newVal)
      }
    }
  }
  // created() {
  //   this.$store.dispatch("changeLocale", this.lang || 'zh-CN');
  // }
}
</script>
