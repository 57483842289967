<template>
  <CModal :show.sync="mutatedShow"
          :closeOnBackdrop="false"
          :title="$t('paymentCrypto.Title')">
    <div class="container-payment-crypto">
      <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
           ref="qrcode"/>
      <span id="amount">{{ amount }} {{ coin }}</span>
      <span id="address">{{ address }}</span>
      <span>{{ $t('paymentCrypto.Note') }}</span>
    </div>
  </CModal>
</template>

<style scoped>
.container-payment-crypto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center
}

#amount {
  margin-top: 20px;
  margin-bottom: 10px;
}

#address {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #80808054;
  color: black;
  font-weight: bold;
}
</style>

<script>
import QRCode from 'qrcode'

const opts = {
  errorCorrectionLevel: 'H',
  type: 'image/jpeg',
  quality: 0.3,
  margin: 1,
  color: {
    dark:"#000000",
    light:"#FFFFFF"
  }
}

export default {
  name: 'PaymentCrypto',
  data() {
    return {
      mutatedShow: false,
    }
  },
  props: ['show', 'amount', 'address', 'coin'],
  watch: {
    show() {
      this.mutatedShow = this.show
    },
    mutatedShow() {
      if (this.mutatedShow) {
        QRCode.toDataURL(this.address, opts, function (err, url) {
          if (err) throw err

          this.setQrCode(url)
        }.bind(this))
      } else {
        if (this.$route.name !== 'TransactionBills') {
          this.$router.push({name: 'TransactionBills'})
        }
        this.setQrCode()
      }
      this.$emit('update:show', this.mutatedShow)
    }
  },
  mounted() {
    this.setQrCode()
  },
  methods: {
    setQrCode(src) {
      if (src) {
        this.$refs.qrcode.src = src
      } else {
        this.$refs.qrcode.src = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
      }
    }
  }
}
</script>
