<template>
  <CModal
    name="contactModal"
    :title="$t('contact_us.ContactUs')"
    size="lg"
    color="info"
    :show.sync="contactModal"
    id="contactModal"
  >
    <CRow>
      <CCol sm="12">
        <CRow>
          <CCol md="12">
            <div class="info-box">
              <i class="bx bx-map"></i>
              <h3>{{ $t('contact_us.contact_us_message') }}：</h3>
            </div>
          </CCol>
        </CRow>
        <Contacts />
      </CCol>
    </CRow>
  </CModal>
  <!-- End Contact Section -->
</template>

<script>
import { EventBus } from "@/plugins/event-bus.js";
import Contacts from './Contacts'

export default {
  name: "TheContact",
  components: {
    Contacts,
  },
  data() {
    return {
      contactModal: false
    };
  },
  props: {},
  methods: {
    listenEvent() {
      // Send the event on a channel (i-got-clicked) with a payload (the click count.)
      EventBus.$on("show-contact", () => {
        this.contactModal = true;
      });
    }
  },
  created() {
    // this.selectedDomain = newDomain;
    this.listenEvent();
  }
};
</script>
