<template>
  <div class="c-app" >
    <CWrapper>
      <TheHeader />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <flash-message class="flashpool"></flash-message>

            <transition name="fade">
              <router-view></router-view>
            </transition>
            <TheContact />
          </CContainer>
        </main>
        <TheFooter/>
      </div>
    </CWrapper>
  </div>
</template>

<style scoped>
@import '../vue-flash-message/flashpool.css';
</style>
<script>
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
import TheContact from './TheContact'

export default {
  name: 'TheContainer',
  components: {
    TheHeader,
    TheFooter,
    TheContact
  },
  data() {
    return {

    };
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
