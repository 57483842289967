<template>
  <CHeader fixed with-subheader light>

    <CHeaderBrand class="mr-4" to="/">
      <img :src="logo_url" style="height:40px" />
    </CHeaderBrand>

    <CHeaderNav class="d-md-down mr-auto">
      <!-- <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">
          {{ $t('nav.Dashboard') }}
        </CHeaderNavLink>
      </CHeaderNavItem> -->

      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/domain/list" exact>
          {{ $t('nav.Domain') }}
        </CHeaderNavLink>
      </CHeaderNavItem>

      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dns/index" exact>
          {{ $t('nav.DNS') }}
        </CHeaderNavLink>
      </CHeaderNavItem>

      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/cert/index" exact>
          {{ $t('nav.Cert') }}
        </CHeaderNavLink>
      </CHeaderNavItem>

      <!--
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/user/member" exact>
          {{ $t('nav.Member') }}
        </CHeaderNavLink>
      </CHeaderNavItem>
      -->
      <CHeaderNavItem class="px-3" id="member-plans">
        <CHeaderNavLink to="/member/plans" exact>
          {{ $t('nav.Member') }}
        </CHeaderNavLink>
      </CHeaderNavItem>

      <CHeaderNavItem class="px-3" id="transaction-bills">
        <CDropdown :togglerText="$t('nav.Transaction')"
                   nav
                   placement="bottom-end">
          <CDropdownItem to="/transaction/bills" exact>
            {{ $t('nav.Bill') }}
          </CDropdownItem>
          <!--<CDropdownItem to="/transaction/invoices" exact>
            {{ $t('nav.Invoice') }}
          </CDropdownItem>-->
        </CDropdown>
      </CHeaderNavItem>

      <CHeaderNavItem class="px-3">
        USDT {{ balance }}
        <span @click="fetchBalance()">
          <CIcon name='cil-reload'
                 class="ml-2"
                 :class="{spin: onReloadBalance}"
                 id="reload-icon"/>
        </span>
        <CButton color="primary"
                 size="sm"
                 class="ml-2"
                 @click="showModal = true">
          {{ $t('nav.TopUp') }}
        </CButton>
        <CModal
          :title="$t('nav.TopUp')"
          :show.sync="showModal"
          :closeOnBackdrop="false">
          <CInput :label="$t('nav.Amount') + '(USDT)'"
                  v-model="amount"/>

          <ul id="coins">
            <li v-for="coin in coinSupports"
                :key="'coin-' + coin.id"
                @click="clickCoins(coin.code, $event)">
              <div class="box"></div>
              <img :src="coin.icon"/>
              <p>{{ coin.name }}</p>
            </li>
          </ul>
          <template v-slot:footer>
            <button
                type="button"
                class="btn btn-secondary"
                @click="showModal=false">
              {{ $t('Cancel') }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="clickTopUp()">
              {{ $t('Pay') }}
            </button>
          </template>
        </CModal>
        <payment-crypto :show.sync="showPaymentCrypto"
                        :amount="payment.amount"
                        :address="payment.address"
                        :coin="payment.currency2"/>
      </CHeaderNavItem>

    </CHeaderNav>

    <CHeaderNav class="float-right">
      <KeycloakHeaderAccount />
    </CHeaderNav>

    <!--
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader>
    -->
  </CHeader>
</template>

<script>
import KeycloakHeaderAccount from './KeycloakHeaderAccount'
import axios from '@/plugins/axios'

import PaymentCrypto from '../views/payment/Crypto.vue'

export default {
  name: 'TheHeader',
  components: {
    KeycloakHeaderAccount,
    PaymentCrypto,
  },
  data () {

    var company_logo_url = '';
    if (window.location.hostname.includes("ujcdn")) {
      company_logo_url = "logos/ujcdn-logo.png";
    } else if (window.location.hostname.includes("suyuncdn")) {
      company_logo_url = "logos/suyuncdn-logo.png";
    }

    return {
      amount: 0,
      selectedCoin: '',
      showModal: false,
      onReloadBalance: false,
      logo_url: company_logo_url,
      coinSupports: [],
      showPaymentCrypto: false,
      payment: {
        currency2: null,
        address: null,
        amount: 0
      }
    }
  },
  computed: {
    balance() {
      return this.$store.state.balance
    }
  },
  watch: {
    showModal() {
      if (!this.showModal) {
        this.selectedCoin = ''
        this.amount = 0
        const li = document.querySelectorAll('ul#coins li')
        li.forEach(item => {
          item.classList.remove('Active')
        })
      }
    }
  },
  created() {
    this.fetchBalance()
    this.fetchCoinSupport()
    // this.$store.dispatch('fetchCheckout')
  },
  methods: {
    fetchBalance() {
      this.onReloadBalance = true
      axios.get('plan/balance/me/').then(response => {
        this.$store.state.balance = response.data.amount
        this.onReloadBalance = false
      }).catch(error => console.log(error))
    },
    fetchCoinSupport() {
      axios.get('plan/coin-support/').then(response => {
        this.coinSupports = response.data
      }).catch(error => {
        console.log(error)
      })
    },
    clickCoins(coin, $event) {
      const li = document.querySelectorAll('ul#coins li')
      li.forEach(item => {
        item.classList.remove('Active')
      })
      const $target = $event.target
      $target.parentNode.classList.toggle('Active')
      this.selectedCoin = coin
    },
    clickTopUp() {
      if (!this.amount) {
        this.flash(this.$i18n.t('nav.WarningAmount'), 'warning')
        return
      }
      if (!this.selectedCoin) {
        this.flash(this.$i18n.t('nav.WarningCoin'), 'warning')
        return
      }

      // if (this.amount < 200) {
      //   this.flash(this.$i18n.t('nav.WarningMinimumAmount'), 'warning')
      //   return
      // }


      axios.post('plan/balance/topup/', {amount: this.amount, currency2: this.selectedCoin}).then(response => {
        this.showModal = false
        this.fetchBalance()
        this.selectedCoin = ''
        this.amount = 0
        const li = document.querySelectorAll('ul#coins li')
        li.forEach(item => {
          item.classList.remove('Active')
        })
        this.showPaymentCrypto = true
        this.payment = response.data
      }).catch(error => {
        error.forEach(item => {
          this.flash(item, 'warning')
        })
      });
    }
  }
}
</script>

<style scoped>
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
#reload-icon {
  cursor: pointer
}

#reload-icon.spin {
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

</style>
